/* Globals */

body {
    font-family: futura-pt, sans-serif;
    overflow-x: hidden;
}

header {
    text-align: center;
    text-transform: uppercase;
}

footer {
    text-align: center;
    text-transform: uppercase;
}

section,
.section {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background: #fff;
    padding: 60px 24px;
}

article {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
}

h1 {
    font-size: 125px;
    line-height: 105px;
    font-weight: 800;
    margin: 16px 0 16px -8px;
    font-family: futura-pt-bold, sans-serif;
    letter-spacing: -8px;
}

@media (max-width: 1040px) {

    h1 {
        font-size: 70px;
        line-height: 60px;
        letter-spacing: -4px;
    }
}

h2 {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 40px;
    color: #555b70;
    margin: 16px 0;
    font-weight: 800;
    font-family: futura-pt-bold, sans-serif;
}

@media (max-width: 480px) {

    h2 {
        font-size: 20px;
        line-height: 20px;
    }
}

a {
    color: #555b70;
    font-weight: 700;
    text-decoration: none;
    font-family: futura-pt, sans-serif;
}

a.button {
    background: #3A5291;
    border: 1px solid #3A5291;
    display: inline-block;
    padding: 24px 48px;
    border-radius: 48px;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: ease 0.5s;
}

a.button:hover {
    background: #e2e4ec;
    border: 1px solid #e2e4ec;
    color: #3A5291;
    transition: ease 0.5s;
}

.wrap {
    margin: 0 auto;
    max-width: 1280px;
}

.section__wrap,
.footer__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.section__header,
.footer-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.section__header p {
    font-size: 20px;
    line-height: 28px;
    color: #565961;
    font-weight: 500;
    letter-spacing: 2px;
    margin-top: 0;
}

@media (max-width: 800px) {

    .section__header p {
        font-size: 15px;
        line-height: 20px;
    }
}


.author {
    display: block;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 32px;
    margin-top: 24px;
    letter-spacing: 2px;
    font-weight: 400;
}

.author__title {
    display: block;
}


#page {
    overflow: hidden;
    background: #fff;
}



/* Header */

.header {
    background: #2b3456;
    color: #fff;
    padding: 16px 24px;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 24px #000;
}

.header__text {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 4px;
}

@media (max-width: 800px) {

    .header__text {
        letter-spacing: 2px;
    }
}


/* BG - swatches */

.bg-swatches {
    position: absolute;
    background-image: url("/inc/img/bg-swatches.png");
    right: -40px;
    top: -40%;
    z-index: 1;
    width: 167px;
    height: 1050px;
    background-size: 167px;
    background-repeat: no-repeat;
}

@media (max-width: 1040px) {

    .bg-swatches {
        opacity: 0!important;
    }
}


/* Sections */

/* Section - Feature */

.section-feature {
    background: #242424;
    background-image: url("/inc/img/bg-wood.jpg");
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: left;
    transform: skewY(-3deg);
    z-index: 1;
    padding-top: 120px;
    padding-bottom: 32px;
    margin-top: -70px;
}

@media (max-width: 1140px) {

    .section-feature {
        padding-top: 80px;
    }
}

.section-feature:before {
    content: '';
    background: linear-gradient(-5deg, rgba(20,20,20,0.7), rgba(20,20,20,0.1));
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
}

/* For hiding top left white background */
.section-feature:after {
    background: #000;
    content: '';
    top: -98px;
    left: 0;
    width: 100%;
    position: absolute;
    height: 100px;
}

.section-feature .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

@media (max-width: 1040px) {

    .section-feature .section__wrap {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.section-feature__header {
    text-align: left;
}

@media (max-width: 1040px) {

    .section-feature__header {
        text-align: center;
    }
}

@media (max-width: 540px) {

    .section-feature__header {
        text-align: center;
    }
}

.section-feature__article {
    text-align: left;
    z-index: 1;
    /* Reset transform */
    transform: skewY(3deg);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 540px) {

    .section-feature__article {
        text-align: center;
    }
}

@media (max-width: 1040px) {

    article.section-feature__article:nth-of-type(1) {
        order: 2;
    }
}

.section-feature__article:nth-of-type(2) {
    padding: 0 0 0 80px;
    max-width: 510px;
}

@media (max-width: 1435px) {

    .section-feature__article:nth-of-type(2) {
        padding-left: 0;
        padding-right: 80px;
    }
}

@media (max-width: 1040px) {

    .section-feature__article:nth-of-type(2) {
        padding-right: 0;
        text-align: center;
        max-width: none;
    }
}

@media (max-width: 540px) {

    .section-feature__article:nth-of-type(2) {
        padding-right: 0;
    }
}

.section-feature__image {
    margin: 0;
    margin-top: -40px;
    margin-left: -60px;
}

@media (max-width: 1040px) {

    .section-feature__image {
        max-width: 600px;
        margin: 0 auto;
    }
}

@media (max-width: 1280px) {

    .section-feature__image img {
        width: 100%;
        height: auto;
    }
}

.section-feature__logo {
    margin: 0;
    margin-top: 102px;
}

@media (max-width: 1040px) {

    .section-feature__logo {
        margin-top: 40px;
    }
}

.section-feature__definition {
    color: #9b9eac;
    font-size: 18px;
    text-transform: none;
    max-width: 420px;
}

@media (max-width: 1040px) {

    .section-feature__definition {
        border-bottom: 1px solid rgba(255,255,255,0.5);
        padding-bottom: 40px;
        margin: 0 auto;
    }
}

.section-feature__definition p {
    margin: 8px 0px;
}

.section-feature__definition small {
    font-size: 12px;
}

.section-feature__intro {
    font-size: 70px;
    font-weight: 100;
    line-height: 70px;
    margin: 100px 0;
    letter-spacing: -4px;
    max-width: 410px;
}

@media (max-width: 1300px) {

    .section-feature__intro {
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -3px;
        max-width: 365px;
    }
}

@media (max-width: 1040px) {

    .section-feature__intro {
        max-width: none;
        margin: 32px 0;
    }
}

@media (max-width: 540px) {

    .section-feature__intro {
        font-size: 37px;
        line-height: 42px;
        letter-spacing: -2px;
        margin: 40px 0 60px;
        max-width: none;
    }
}


/* Section - Compare (Before/after slider) */

/* Imgslider before/after */

.section-compare .beer-slider {
    margin: 40px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 500px;
}

@media (max-width: 540px) {

    .section-compare .beer-slider {
        height: 350px;
    }
}

.section-compare .beer-reveal {
    background: #fff;
    border-right: 4px double #ddd;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.slider .instruction {
    display: none;
}


/* Section - Why */

.section-why {
    background: #2b3456;
    color: #fff;
}

.section-why h2 {
    color: #fff;
}

.section-why .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

/* 3 columns */
.section-why article {
    width: calc(33.33% - 32px);
    height: 300px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #0d1534;
    margin: 40px 16px 0 16px;
    padding: 0 40px;
}

.section-why article p {
    font-size: 34px;
    font-weight: 400;
    line-height: 50px;
    max-width: 700px;
}

@media (max-width: 540px) {

    .section-why article p {
        font-size: 24px;
        line-height: 40px;
    }
}

.section-why article:nth-of-type(1) {
    background: transparent;
    border: 1px solid rgba(255,255,255,0.25);
    overflow: hidden;
    padding: 24px;
    width: 100%;
    height: auto;
}

.section-why article:nth-of-type(3) {
    background: #fff;
}

/* Note: Need wood texture */
.section-why article:nth-of-type(4) {
    background: #242424;
    background-image: url("/inc/img/bg-wood.jpg");
    background-size: cover;
    background-size: 800px;
}

@media (max-width: 1040px) {

    .section-why article:nth-of-type(2),
    .section-why article:nth-of-type(3),
    .section-why article:nth-of-type(4) {
        height: auto;
        padding: 40px;
    }
}

@media (max-width: 740px) {

    .section-why article {
        width: 100%;
    }
}

.section-why article img {
    width: 100%;
    max-width: 250px;
}

.section-why .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


/* Section - History */

/* 3 columns */

.section-history .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/* 3 columns */
.section-history article {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px 0 0;
}

@media (max-width: 800px) {

    .section-history article {
        margin: 40px 16px 0 16px;
    }
}

.section-history article:nth-of-type(1) img {
    width: 100%;
    max-width: 1000px;
}

@media (max-width: 800px) {

    .section-history article:nth-of-type(1) img {
        max-width: 350px;
    }
}


/* Section - Examples */

.section-examples {
    background: #e2e4ec;
    padding: 60px 24px 100px;
}

.section-examples .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

/* 2 columns */
.section-examples article {
    width: calc(50% - 32px);
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px 16px 0;
    height: 250px;
    background: #0d1534;
    overflow: hidden;
    position: relative;
}

/* 3 columns - mixed widths */
.section-examples article:nth-of-type(3) {
    width: calc(22.22% - 32px);
}

@media (max-width: 1040px) {

    .section-examples article:nth-of-type(3) {
        width: calc(50% - 32px);
    }
}

.section-examples article:nth-of-type(4) {
    width: calc(66.66% - 32px);
}

@media (max-width: 1040px) {

    .section-examples article:nth-of-type(4) {
        width: calc(50% - 32px);
    }
}

.section-examples article:nth-of-type(5) {
    width: calc(11.11% - 32px);
}

@media (max-width: 1040px) {

    .section-examples article:nth-of-type(5) {
        display: none;
    }
}

.section-examples article:nth-of-type(6) {
    width: 100%;
    height: 600px;
}

.section-examples img {
    width: 100%;
    display: block;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

/* stack */
@media (max-width: 740px) {

    .section-examples article {
        height: auto;
    }

    .section-examples article:nth-of-type(1),
    .section-examples article:nth-of-type(2),
    .section-examples article:nth-of-type(3),
    .section-examples article:nth-of-type(4) {
        width: 100%;
    }

    .section-examples p {
        margin: 0;
    }

    .section-examples img {
        position: relative;
    }

   .section-examples article:nth-of-type(6) {
        height: 250px;
    }
   .section-examples article:nth-of-type(6) img {
        position: absolute;
    }
}



/* Section - Testimonials */

.section-testimonials {
    background: #242424;
    color: #fff;
    padding-bottom: 150px;
}

.section-testimonials h2 {
    color: #fff;
}

.section-testimonials .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

/* 2 columns */
.section-testimonials article {
    width: calc(50% - 32px);
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px 16px 0 16px;
    background: transparent;
    border: 1px solid rgba(255,255,255,0.25);
    overflow: hidden;
    padding: 40px;
}

@media (max-width: 1100px) {

    .section-testimonials article {
        width: 100%;
    }
}

.section-testimonials article:nth-of-type(2) {
    max-height: 550px;
    border: none;
}

@media (max-width: 1100px) {

    .section-testimonials article:nth-of-type(2) {
        height: 500px;
    }
}

@media (max-width: 540px) {

    .section-testimonials article:nth-of-type(2) {
        height: 300px;
    }
}

.section-testimonials p {
    font-size: 34px;
    font-weight: 400;
    line-height: 50px;
}

@media (max-width: 540px) {

    .section-testimonials p {
        font-size: 24px;
        line-height: 40px;
    }
}

.section-testimonials img {
    width: 100%;
    display: block;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

@media (max-width: 1100px) {

    .section-testimonials img {
        position: relative;
        max-width: 600px;
    }
}

.section-testimonials article:nth-of-type(2) {
    padding: 0;
    position: relative;
}


/* Section - Testimonials - Video */

.section-testimonials--video {
    /*background: #e2e4ec;*/
    background: #141414;
    transform: skewY(-3deg);
    margin-top: -60px;
    padding-bottom: 120px;
}

.section-testimonials--video h2 {
    color: #fff;
}

.section-testimonials--video .wrap {
    width: 100%;
    transform: skewY(3deg)!important;
}

.section-testimonials--video article {
    width: calc(100% - 32px);
    background: #ddd;
    margin: 40px 16px 0 16px;
}



/* Section - REW */

.section-rew {
    background: #fff;
    z-index: 1;
    margin-top: -60px;
    padding-bottom: 100px;
}

.section-rew .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

/* 2 columns */
.section-rew article {
    width: calc(50% - 32px);
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px 16px 0 16px;
    background: #e2e4ec;
    overflow: hidden;
    padding: 48px 100px;
    min-height: 300px;
}

@media (max-width: 1040px) {

    .section-rew article {
        width: 100%;
        padding: 48px 32px;
        min-height: unset;
    }
}

.section-rew p {
    font-size: 34px;
    font-weight: 400;
    line-height: 50px;
}

@media (max-width: 540px) {

    .section-rew p {
        font-size: 24px;
        line-height: 40px;
    }
}

.section-rew img {
    width: 100% !important;
    display: block;
    max-width: 400px;
}

.section-rew article:nth-of-type(2) {
    background: #242424;
    background-image: url("/inc/img/bg-wood.jpg");
    background-size: cover!important;
    color: #fff;
    background-size: 800px;
}


.section-rew--agency {
    width: 100%;
    background: #141414;
    padding-bottom: 90px;
}

.section-rew--agency h2 {
    color: #fff;
}

.section-rew--agency article {
    width: 100%;
    border: none;
    padding-bottom: 32px;
    padding-top: 0;
    margin-top: 24px;
    padding-bottom: 80px;
    margin-bottom: -40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.section-rew--agency article p {
    max-width: 500px;
    color: #fff;
    font-weight: 400;
    font-size: 34px;
}

.section-rew--agency article p:first-of-type {
    margin-top: 0;
}

.section-rew--agency article p:last-of-type {
    margin-top: 64px;
    border-top: 1px solid rgba(255,255,255,0.1);
    padding-top: 64px;
}

.section-rew--agency article a {
    color: #e2e4ec;
}



/* Section - Quote */

.section-quote {
    background: #2b3456;
    background-image: url("/inc/img/bg-quote.png");
    background-size: cover;
    color: #fff;
    transform: skewY(-3deg);
    z-index: 1;
    padding-top: 120px;
    margin-top: -70px;
    overflow: hidden;
}

.section-quote .bg-a-curve {
    background-image: url("/inc/img/bg-a-curve.png");
    width: 500px;
    height: 500px;
    position: absolute;
    left: 50px;
    top: 50px;
    z-index: 1;
    display: block;
    background-size: 500px;
    background-repeat: no-repeat;
}

.section-quote .section__wrap {
    z-index: 2;
}

.section-quote article {
    /* Reset transform */
    transform: skewY(3deg);
}

.section-quote p {
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    font-weight: 300;
    max-width: 1000px;
}

@media (max-width: 960px) {

    .section-quote p {
        font-size: 32px;
        line-height: 40px;
        margin: 0;
    }
}

.section-quote .author {
    font-size: 24px;
    font-weight: 500;
}


/* Section - Footer */

.footer {
    background: #fff;
}

.footer .section__wrap {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/* 2 columns */
.footer article {
    width: calc(50% - 32px);
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 24px 0 0 0;
    background: transparent;
    overflow: hidden;
}

@media (max-width: 680px) {

    .footer article {
        width: 100%;
    }
}

.footer h2 {
    font-size: 20px;
    font-weight: 500;
    font-family: futura-pt, sans-serif;
    letter-spacing: 4px;
}

.footer p {
    font-size: 34px;
    font-weight: 300;
    line-height: 50px;
}

.footer img {
    width: 100%;
    max-width: 220px;
    display: block;
}
